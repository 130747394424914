import $ from 'jquery';
import Cookies from "js-cookie";

$(document).ready(function(){

    if(Cookies.get('cookies') === 'yes'){
        $('.cookies').fadeOut(300);
    }else{
        $('.cookies').fadeIn(300);
    }

    $('#cookies-accept').on('click', function() {
        Cookies.set('cookies', 'yes');
        $('.cookies').fadeOut(300);
    });
});
