import $ from 'jquery';
import 'bootstrap';
import './lity.min.js';
import './events.js';

//components
import './components/ie';
import './components/menu';
import './components/navigation';
import './components/breadcrumbs';
import './components/popup';
// import './components/top';
import './components/tabs';
import './components/contact';
import './components/box';
import './components/cookies';
import './components/footer';
import './components/intro';
import './components/navigation_library';
import './components/contact-box';

import './components/jquery.cssmap.min';

//global functions

//tooltips enable
$(function () {

    $('[data-toggle="tooltip"]').tooltip();
    jQuery("#map-poland").CSSMap({
        "size": 540,
        "agentsList": {
            "enable": true,
            "agentsListId": "#list-data",
            "agentsListSpeed": 0,
            "agentsListOnHover": false
        },
        onClick: function (e){
            let href = $(e).children('a').attr('href')
            jQuery(`[data-region]`).removeClass('active')
            jQuery(`[data-region="${href}"]`).addClass('active')
        },
        onSecondClick: function (e){
            let href = $(e).children('a').attr('href')
            jQuery(`[data-region="${href}"]`).removeClass('active')
        }
    });
});
