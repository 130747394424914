import $ from 'jquery';

$(document).ready(function () {
    $(document).on("click", "[data-tab]", function () {
        var $el = $(this);
        var path = $el.data("tab");
        var target = $el.data("tab-target");

        $(target).addClass("loader");

        $.get(path, {}, function (data) {
            $(".tabs").removeClass("active");
            $el.parents(".tabs").addClass("active");
            if (data.status) {
                $(target).html(data.content);
                let url = $el.attr('href');
                history.pushState(null, null, url);
            }
            $(target).removeClass("loader");
        }, "json");
        return false;
    });
});