$(document).ready(function() {
  ieDetect();
  $('.ie-popup__close').on('click', function() {
    $('.ie-popup').fadeOut(400);
    $('.mask').fadeOut(400);
  });
});

function ieDetect() {
  const isIE = /*@cc_on!@*/false || !!document.documentMode;
  if(isIE) {
    $('.ie-popup').fadeIn(400);
    $('.mask').fadeIn(200);
  }
}