"use strict";

document.addEventListener("DOMContentLoaded", function () {
  var contactElem = document.getElementById('preferredContactForm');

  var changeEventHanlder = function changeEventHanlder(evt) {
    var selectedIndex = evt.target.selectedIndex;
    var phoneInput = document.getElementById('phone');

    if (phoneInput.value === '') {
      if (selectedIndex === 1) {
        var _phoneVal = document.getElementById('phoneVal');

        phoneInput.setAttribute('required', '');
        _phoneVal.innerHTML = 'Podaj numer telefonu';
        _phoneVal.style.opacity = 1;
      } else {
        phoneVal.style.opacity = 0;
        phoneInput.removeAttribute('required');
      }
    }
  };

  if (contactElem) {
    contactElem.onchange = changeEventHanlder;
  }
});