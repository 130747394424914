import $ from 'jquery';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

$(document).ready(function(){
    if( $('.boxOnBar--el4 > div').length > 4){
        $('.boxOnBar--el4').addClass('owl-carousel').owlCarousel({
            loop:true,
            responsive:{
                0:{
                    items:1,
                    nav:false,
                    margin:0,
                },
                500:{
                    items:2,
                    nav:false,
                    margin:10,
                },
                900:{
                    items:3,
                    nav:false,
                    margin:10,
                },
                1200:{
                    items:4,
                    nav:true,
                    margin:10,
                }
            }
        });
    }
    if( $('.boxOnBar--el3 > div').length > 3){
        $('.boxOnBar--el3').addClass('owl-carousel').owlCarousel({
            loop:true,
            margin:10,
            nav:false,
            items: 3,
            responsive:{
                0:{
                    items:1,
                    nav:false,
                    margin:0,
                },
                500:{
                    items:2,
                    nav:false,
                    margin:10,
                },
                1000:{
                    items:3,
                    nav:false,
                    margin:10,
                },
                1200:{
                    items:3,
                    nav:true,
                    margin:10,
                }
            }
        });
    }
    if( $('.boxOnBar--el2 > div').length > 2){
        $('.boxOnBar--el2').addClass('owl-carousel').owlCarousel({
            loop:true,
            margin:10,
            nav:false,
            items: 2,
            responsive:{
                0:{
                    items:1,
                    nav:false,
                    margin:0,
                },
                1200:{
                    items:2,
                    nav:true,
                    margin:10,
                }
            }
        });
    }
});