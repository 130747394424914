import $ from 'jquery';

$(document).ready(function(){

    menuIconHover();
    
    $('.navList__sections li').each(function(){
        if($(this).hasClass('active')){
            const id = $(this).attr('data-id');
            $('.top svg #highlight #' + id).addClass('active');
        }
    });

});
        
        
function menuIconHover(){
    let id;
    $('.navbar li').on({
        mouseenter: function () {
            $('.navList__sections li').each(function(){
                if($(this).hasClass('active')){
                    id = $(this).attr('data-id');
                    $('.navList__sections li').removeClass('active');
                }
            });
            $(this).addClass('active-tooltip');
            $('#highlight #' + id).removeClass('active');
        },
        mouseleave: function () {
            $(this).removeClass('active-tooltip');
            $('.navList__sections li[data-id = '+ id +']').addClass('active');
            setTimeout(function(){
                $('#highlight #' + id).addClass('active');
            },100)
        }
    });
}