import $ from 'jquery';

$(document).ready(function(){
    // $('.mapInfo').on({
    //     mouseenter: function () {
    //         const id = $(this).attr('data-id');
    //         $('#contact-map #g'+id).addClass('active');
    //     },
    //     mouseleave: function () {
    //         $('#contact-map g').removeClass('active');
    //     }
    // });

    //accordions
    $('.accLink').on({
        click: function () {
            const id = $(this).parent().parent().parent().attr('id');
            const accToOpen = $('[data-id="'+id+'"] button').attr('data-target');

            $('.collapse').collapse('hide');
            setTimeout(function(){
                $('html, body').animate({
                    scrollTop: $('[data-id="'+id+'"]').offset().top
               }, 400, function(){
                   $(accToOpen).collapse('toggle');
               });
            }, 300);


        }
    });
});
