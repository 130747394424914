import $ from 'jquery';

$(document).ready(function(){

    $('.menu').on('click', function() {
        if(!$(this).hasClass('active')){
            openMenu();
            $(this).addClass('active');
            $('body').addClass('hideOverflow');            
        }else{
            $(this).removeClass('active');
            $('.navigation').slideUp(600);
            $('body').removeClass('hideOverflow');   
        }
    });

    $('.navigation__list--one').on('click', 'button', function(e) {
        e.preventDefault();
        $('.navigation__list--two').html('').removeClass('navigation__list');
        $('.navigation__list--three').html('').removeClass('navigation__list');

        var $el = $(this);
        var path = '/menu.json?parentSlug=' + $el.attr('data-slug');
        $('.navigation__list--one li').removeClass('active');
        $el.parent().addClass('active');
        $.get(path, {}, function(data){
            let li = '<li><a href="'+data.parent.url+'" data-slug="'+data.parent.slug+'">'+data.parent.title+'</a></li>';
            for(let i = 0; i < data.level.length; i++){
                li += '<li class="arrow"><button data-children="'+data.level[i].hasChildren+'" data-slug="'+data.level[i].slug+'">'+data.level[i].title+'</button></li>';
            }
            $('.navigation__list--two').html(li).addClass('navigation__list');
        }, "json");
        return false;
    });

    $('.navigation__list--two').on('click', 'button', function(e) {
        e.preventDefault();
        var $el = $(this);
        var path = '/menu.json?parentSlug=' + $el.attr('data-slug');
        $('.navigation__list--two li').removeClass('active');
        $el.parent().addClass('active');
        $.get(path, {}, function(data){
            let li = '<li><a href="'+data.parent.url+'" data-slug="'+data.parent.slug+'">'+data.parent.title+'</a></li>';
            for(let i = 0; i < data.level.length; i++){
                li += '<li><a href="'+data.level[i].url+'" data-children="'+data.level[i].hasChildren+'" data-slug="'+data.level[i].slug+'">'+data.level[i].title+'</a></li>';
            }
            $('.navigation__list--three').html(li).addClass('navigation__list');
        }, "json");
        return false;
    });

});

function openMenu() {
    var $el = $(this);
    var path = '/menu.json';
    $el.parent().addClass('active');
    $.get(path, {}, function(data){
        let vademecum = '';
        for(let i = 0; i < data.vademecum.length; i++){
            var item = data.vademecum[i];
            vademecum += '<li class="arrow"><button data-children="'+item.hasChildren+'" data-slug="'+item.slug+'">'+item.title+'</button></li>';
        }
        $('[data-navigation=vademecum]').html(vademecum);
        
        let bim = '';
        for(let i = 0; i < data.bim.length; i++){
            var item = data.bim[i];
            bim += '<li><a href="'+item.url+'">'+item.title+'</a></li>';
        }
        $('[data-navigation=bim]').html(bim);
        
        $('.navigation').slideDown(400);
    }, "json");
    return false;
}
