import Cookies from "js-cookie";

$(document).ready(function() {
  if(!Cookies.get('contact-info')) {
    contactInfo();
  }
})

function contactInfo() {
  setTimeout(function() {
    $('.contact-info').addClass('active')
    setTimeout(function() {
      $('.contact-info').removeClass('active')
      Cookies.set('contact-info', 'yes');
    }, 8000)
  }, 30000)
}