$(document).ready(function () {
    // bind ga-events when google-analytics is loaded
    if (typeof (gtag) !== 'unefined') {
        $('.libraryDownload__list a').on('click', function () {
            gtag('event', 'buttonClick', {
                'event_category': 'download',
                'event_label': $(this).data('name')
            });
            return true;
        });
    }
});