"use strict";

document.addEventListener("DOMContentLoaded", function () {
  var width = window.innerWidth;
  var maxLength = width < 580 ? 50 : 25;
  var elements = document.querySelectorAll('.breadcrumbs li a');
  elements.forEach(function (el) {
    if (el.textContent.length > maxLength) {
      var newEl = el.textContent.slice(0, maxLength) + '...';
      el.innerHTML = newEl;
    }
  });
});