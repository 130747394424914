//scroll to
$(window).on('load', function() {
    $(function () {

      if(window.location.href.split('#')[1] != undefined) {
        let id = window.location.href.split('#')[1].split("-")[1];
      
        // let dataParam = $("[data-note='"+id+"']");
        let dataParam = $("table tr td strong:contains('["+id+"]')");
          
        // if($("table tr td strong:contains('["+id+"]')")){
        //     console.log("jest")
        // }

        //check if element is in p 
        if($("table tr td p strong:contains('["+id+"]')").length){ 
          $("table tr td strong:contains('["+id+"]')").parent().parent().parent().append('<button id="closeBtn" onClick="window.top.close();">Powrót</button>');
        }else {
          $("table tr td strong:contains('["+id+"]')").parent().parent().append('<button id="closeBtn" onClick="window.top.close();">Powrót</button>');
        }
        console.log($("table tr td strong:contains('["+id+"]')").parent().parent())
        console.log('test');
        if(dataParam.length != 0){
            $('html, body').animate({
                scrollTop: $("table tr td strong:contains('["+id+"]')").offset().top - 134
            }, 1500);
        }
      } 
    });
});
